import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_Project = _resolveComponent("Project")!
  const _component_vue_scroll_snap = _resolveComponent("vue-scroll-snap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Navbar),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_vue_scroll_snap, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
            return (_openBlock(), _createBlock(_component_Project, {
              key: project.name,
              project: project,
              class: "item"
            }, null, 8, ["project"]))
          }), 128))
        ]),
        _: 1
      })
    ])
  ]))
}