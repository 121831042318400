
import { defineComponent } from 'vue'
import VueScrollSnap from './../components/ScrollSnap.vue'
import Navbar from './../components/Navbar.vue'
import Project from './../components/Project.vue'
import data from './../data/index'

export default defineComponent({
  name: 'Projects' as string,
  beforeCreate: function () {
    document.body.className = 'gui'
  },
  data () {
    return {
      projects: data.projects
    }
  },
  components: {
    Navbar,
    Project,
    VueScrollSnap
  }
})
