import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "project-wrapper" }
const _hoisted_2 = { class: "block-left" }
const _hoisted_3 = { class: "project-title" }
const _hoisted_4 = { class: "project-description" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "block-right" }
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.project?.name), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", { innerHTML: _ctx.markdownDescription }, null, 8, _hoisted_5)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("a", {
        href: _ctx.project?.url
      }, "GOTO", 8, _hoisted_7)
    ])
  ]))
}