
import { defineComponent } from 'vue'

import Navbar from './../components/Navbar.vue'

export default defineComponent({
  name: 'About' as string,
  beforeCreate: function () {
    document.body.className = 'gui'
  },
  components: {
    Navbar
  }
})
