
import { defineComponent } from 'vue'

import { Execution } from './../types'
import LogLine from './LogLine.vue'
import WelcomeMessage from './WelcomeMessage.vue'

interface History {
  history: Array<Execution>;
}

export default defineComponent({
  name: 'History' as string,
  computed: {
    history: {
      get (): Array<Execution> {
        return this.$store.state.history
      },
      set (value: Array<Execution>): void {
        this.$store.state.history = value
      }
    }
  },
  components: {
    LogLine,
    WelcomeMessage
  },
  props: {
    prompt: String
  }
})
