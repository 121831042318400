import { Command, ParsedCommand, State, Store } from './../types'

export default class Ls extends Command {
  constructor () {
    super('...args', 'return this.execute(...args)')
    return this.bind(this)
  }

  execute (state: State, pc: ParsedCommand): string {
    // TODO: handle multiple inputs at once
    let directory = state.filesystem.getCurrentDir()
    const path = pc.args.length > 0 ? pc.args[0] : state.filesystem.pwd // For now only support single argument
    const normalizedPath = state.filesystem.normalizePath(path)
    directory = state.filesystem.getDir(normalizedPath)

    if (directory === undefined) {
      return pc.args[0] + ' not found.'
    }
    return directory.content.map(val => { return val.name }).join(' ')
  }

  autocomplete (store: Store, args: string): string {
    console.log('ls autocomplete for args=' + args)
    let path = args.trim()
    if (args.trim() === '') {
      path = store.state.filesystem.pwd
    } else {
      path = store.state.filesystem.normalizePath(path)
    }

    console.log('Path after normalize: ' + path)

    const parentPath = store.state.filesystem.getParentPath(path)
    console.log('Parent path: ' + parentPath)
    const toAutocompletePath = path.substring(parentPath.length)
    console.log('To autocomplete: ' + toAutocompletePath)
    const dir = store.state.filesystem.getDir(parentPath)

    if (dir === undefined) {
      return ''
    }

    console.log([...dir.content.keys()].join(' '))
    return ''
  }
}
