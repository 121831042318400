import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Root from '../views/Root.vue'
import Home from '../views/Home.vue'
import Projects from '../views/Projects.vue'
import About from '../views/About.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'root',
    component: Root
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects
  },
  {
    path: '/about',
    name: 'about',
    component: About
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

export { routes }
export default router
