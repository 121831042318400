
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

interface Prompt {
    currentCommand: string;
}

export default defineComponent({
  name: 'Prompt' as string,
  computed: {
    currentCommand: {
      get (): string {
        return this.$store.state.currentCommand
      },
      set (value: string): void {
        this.$store.commit('setCommand', value)
      }
    },
  },
  props: {
    prompt: String
  },
  methods: {
    runCommand (): void {
      this.handleCommand(this.currentCommand)
      this.currentCommand = ''
    },
    scrollToPrompt (): void {
      if (!this.detectMob()) {
        (this.$refs.prompt as HTMLInputElement).focus();
        (this.$refs.prompt as HTMLInputElement).selectionStart = (this.$refs.prompt as HTMLInputElement).selectionEnd = 10000
      }
    },
    detectMob () {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ]

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem)
      })
    },
    ...mapActions([
      'handleCommand'
    ])
  },
  mounted () {
    setInterval(() => {
      this.scrollToPrompt()
    }, 100)
  }
})
