import { Command, File, ParsedCommand, State, Store } from './../types'

export default class Cat extends Command {
  constructor () {
    super('...args', 'return this.execute(...args)')
    return this.bind(this)
  }

  execute (state: State, pc: ParsedCommand): string {
    if (pc.args.length === 0) {
      return ''
    }
    const file = state.filesystem.getCurrentDir().content.filter(value => value.name === pc.args[0])[0]
    if (file === undefined) {
      return 'File ' + pc.args[0] + ' not found.'
    }
    return (file as File).content
  }

  autocomplete (_store: Store, _args: string): string { return '' }
}
