import { Command, ParsedCommand, State, Store } from './../types'

export default class Contact extends Command {
  constructor () {
    super('...args', 'return this.execute(...args)')
    return this.bind(this)
  }

  execute (_state: State, _pc: ParsedCommand): string {
    return `
  You can find me on:

  - <a href="https://github.com/janekbaraniewski" target="_blank">Github</a>
`
  }

  autocomplete (_store: Store, _args: string): string { return '' }
}
