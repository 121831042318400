
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'LogLine' as string,
  data () {
    return {
      output: '' as string,
      outputBuffer: '' as string
    }
  },
  props: {
    prompt: String,
    execution: null
  },
  methods: {
    ...mapActions([
      'scrollToConsoleBottom'
    ]),
    rewriteLetter () {
      this.output += this.outputBuffer[0]
      this.outputBuffer = this.outputBuffer.slice(1)
      if (this.outputBuffer.length > 0) {
        setTimeout(this.rewriteLetter, 5)
      }
    }
  },
  mounted () {
    this.outputBuffer = this.execution.result
    if (this.outputBuffer.length > 0) {
      this.rewriteLetter()
    } else {
      this.output = '\n'
    }
  },
  updated () {
    this.scrollToConsoleBottom()
  }
})
