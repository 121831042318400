
import { defineComponent } from 'vue'

import Console from '../components/Console.vue'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'Root' as string,
  data () {
    return {
      prompt: '[guest@baraniewski.com]$ ' as string
    }
  },
  methods: {
    ...mapActions([
      'handleKey',
      'handleCommand'
    ])
  },
  mounted () {
    window.addEventListener('keydown', this.handleKey)
    setTimeout(() => {
      this.$store.commit('setCommand', 'runapp')
      setTimeout(() => {
        this.handleCommand(this.$store.state.currentCommand)
        this.$store.commit('setCommand', '')
      }, 500)
    }, 2000)
  },
  components: {
    Console
  },
  beforeCreate: function () {
    document.body.className = 'cmd'
  }
})
