import { Project } from './../types'

import baraniewski from './baraniewski'
import kubeserial from './kubeserial'

export default {
  projects: [
    baraniewski,
    kubeserial
  ] as Array<Project>
}
