
import { defineComponent } from 'vue'

// import Navbar from '../components/Navbar.vue'

export default defineComponent({
  name: 'Home' as string,
  beforeCreate: function () {
    document.body.className = 'gui'
    // setTimeout(function () {
    //   const content = document.querySelector('.content')
    //   if (content !== null) {
    //     content.classList.add('visible')
    //   }
    // }, 0)
  },
  components: {
    // Navbar
  }
})
