import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ded4fac0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "prompt" }
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = { clas: "input-group-addon prompt-text" }
const _hoisted_4 = ["autofocus"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.runCommand && _ctx.runCommand(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.prompt), 1),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentCommand) = $event)),
          type: "text",
          name: "input",
          ref: "prompt",
          autocomplete: "off",
          autocorrect: "off",
          autocapitalize: "off",
          spellcheck: "false",
          autofocus: !_ctx.detectMob()
        }, null, 8, _hoisted_4), [
          [_vModelText, _ctx.currentCommand]
        ])
      ])
    ], 32)
  ]))
}