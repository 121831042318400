import { Command, ParsedCommand, State, Store } from './../types'

export default class Exit extends Command {
  constructor () {
    super('...args', 'return this.execute(...args)')
    return this.bind(this)
  }

  execute (_state: State, _pc: ParsedCommand): string {
    window.close()
    return ''
  }

  autocomplete (_store: Store, _args: string): string { return '' }
}
