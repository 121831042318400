import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "console" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_History = _resolveComponent("History")!
  const _component_Prompt = _resolveComponent("Prompt")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_History, { prompt: _ctx.prompt }, null, 8, ["prompt"]),
    _createVNode(_component_Prompt, { prompt: _ctx.prompt }, null, 8, ["prompt"])
  ]))
}