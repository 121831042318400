
import { marked } from 'marked'
import { defineComponent } from 'vue'
import { Project } from './../types'

export default defineComponent({
  name: 'Project' as string,
  props: {
    project: {
      type: Object as () => Project
    }
  },
  computed: {
    markdownDescription () {
      let description = this.project?.description
      if (description === undefined) {
        description = ''
      }
      return marked(description)
    }
  }
})
