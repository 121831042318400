import { Command, ParsedCommand, State, Store } from './../types'

export default class Pwd extends Command {
  constructor () {
    super('...args', 'return this.execute(...args)')
    return this.bind(this)
  }

  execute (state: State, _pc: ParsedCommand): string {
    return state.filesystem.pwd
  }

  autocomplete (_store: Store, _args: string): string { return '' }
}
