import { Project } from './../types'

export default {
  name: 'baraniewski.com',
  shortDescription: 'This site',
  description: `
This site.
`,
  url: 'https://github.com/janekbaraniewski/janekbaraniewski.github.io',
  technologies: [
    'VueJS',
    'Typescript',
    'Sass',
    'Github Pages'
  ]
} as Project
