import { Project } from './../types'

export default {
  name: 'KubeSerial',
  shortDescription: 'Kubernetes operator for managing and working with serial devices',
  description: `
Kubernetes operator for managing and working with serial devices.

Allows you to connect USB device to any physical cluster node and use it inside
pod running anywhere else inside your cluster.


example:

\`\`\`yaml
apiVersion: app.kubeserial.com/v1alpha1
kind: SerialDevice
metadata:
  annotations:
    ...
  labels:
    ...
  name: ender3
spec:
  idProduct: "6001"
  idVendor: "0403"
  manager: octoprint
  name: ender3
\`\`\`
`,
  url: 'https://github.com/janekbaraniewski/kubeserial',
  technologies: [
    'Go',
    'Kubernetes',
    'kubebuilder',
    'Docker',
    'ser2net'
  ]
} as Project
