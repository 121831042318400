import { Command, ParsedCommand, State } from './../types'

import router from '../router'

export default class RunApp extends Command {
  constructor () {
    super('...args', 'return this.execute(...args)')
    return this.bind(this)
  }

  execute (_1: State, _2: ParsedCommand): string {
    setTimeout(function () {
      router.push({
        name: 'home'
      })
    }, 1000)
    return `
Running app....

Loading Content...

Loading Something....

Loading Something else...
    `
  }
}
