
import { defineComponent } from 'vue'

import History from './History.vue'
import Prompt from './Prompt.vue'

export default defineComponent({
  name: 'Console' as string,
  props: {
    prompt: String
  },
  components: {
    History,
    Prompt
  }
})
