import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c787e22c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "console" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WelcomeMessage = _resolveComponent("WelcomeMessage")!
  const _component_LogLine = _resolveComponent("LogLine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WelcomeMessage),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.history, (execution) => {
      return (_openBlock(), _createElementBlock("div", { key: execution }, [
        (_openBlock(), _createBlock(_component_LogLine, {
          execution: execution,
          key: execution,
          prompt: _ctx.prompt
        }, null, 8, ["execution", "prompt"]))
      ]))
    }), 128))
  ]))
}