import { Command, ParsedCommand, State, Store } from './../types'

export default class Echo extends Command {
  constructor () {
    super('...args', 'return this.execute(...args)')
    return this.bind(this)
  }

  execute (state: State, pc: ParsedCommand): string {
    return pc.args.join(' ')
  }

  autocomplete (_store: Store, _args: string): string { return '' }
}
