
type Content = Array<File | Directory>;

interface Project {
  name: string;
  shortDescription: string;
  description: string;
  url: string;
  technologies: Array<string>;
}

interface File {
  name: string;
  content: string;
}

interface Directory {
  name: string;
  content: Content;
}

function isDirectory (obj: any): obj is Directory {
  if (typeof obj.content === 'object') {
    return true
  }
  return false
}

interface Filesystem {
  pwd: string;
  home: string;
  root: Directory;
  getCurrentDir (): Directory;
  getDir (path: string): Directory;
  getFromDir (path: string, dir: Directory): Directory;
  setPath (path: string): boolean;
  expandParentDir (path: string, parent: string): string;
  handleParentDir (path: string): string;
  getParentPath (path: string): string;
  normalizePath (path: string): string;
  addDir (path: string, name: string): boolean;
}

interface Store {
  state: State;
  dispatch (command: string, args?: any): void;
  commit (command: string, args?: any): void;
}

interface State {
  commands: Map<string, Command>;
  currentCommand: string;
  projectsList: Array<Project>;
  filesystem: Filesystem;
  history: Array<Execution>;
  historyIndex: number;
}

interface ParsedCommand {
  command: string;
  args: Array<string>;
}

class Command extends Function implements Command {
  execute (_1: State, _2: ParsedCommand): string {
    return 'NotImplemented'
  }

  autocomplete (_1: Store, _2: string): string {
    return 'NotImplemented'
  }

  unknownCommand (pc: ParsedCommand): string {
    return 'Unknown commnand: ' + pc.command + ' ' + pc.args.join(' ') + '\n' + this.help()
  }

  help (): string {
    return 'NotImplemented'
  }
}

interface Command {
  execute: (state: State, pc: ParsedCommand) => string;
  autocomplete: (store: Store, args: string) => string;
  help: () => string;
  unknownCommand: (pc: ParsedCommand) => string;
}

interface Execution {
  command: ParsedCommand;
  result: string;
}

interface KeyEvent {
  keyCode: number;
  preventDefault: () => void;
}

export {
  Command,
  Content,
  Directory,
  Execution,
  File,
  Filesystem,
  KeyEvent,
  ParsedCommand,
  Project,
  Store,
  State,
  isDirectory
}
